import React, { Component } from 'react';
import { Card, CardBody, Col, NavLink, Navbar, NavbarBrand, Row } from 'react-bootstrap';
import { LoadHomePage } from '../store/MetaStore';


export default class Home extends Component {
  constructor(props) {
    super(props); 
    this.containerRef = React.createRef();
    this.state = LoadHomePage.init;
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.detectMobile);
    this.detectMobile();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.detectMobile);
  }

  detectMobile = () => {
    const isMobile = window.innerWidth <= 768; // You can adjust the threshold as needed
    this.setState({ isMobile });
  };

  handleMouseMove = (e) => {
    if (this.state.isMobile) return;
    const container = e.currentTarget;
    const { left, top, width, height } = container.getBoundingClientRect();
    const mouseX = e.clientX - left;
    const mouseY = e.clientY - top;

    const moveX = (mouseX - width / 2) * -0.3; // 30% movement in opposite direction
    const moveY = (mouseY - height / 2) * -0.3; // 30% movement in opposite direction

    container.style.transform = `translate(${moveX}px, ${moveY}px)`;
  };

  handleMouseLeave = (e) => {
    if (this.state.isMobile) return;
    const container = e.currentTarget;
    container.style.transform = 'translate(0, 0)';
  };

  handleTouchMove = (e) => {
    if (!this.state.isMobile) return;
    const touch = e.touches[0];
    const container = this.containerRef.current;
    const { left, top, width, height } = container.getBoundingClientRect();
    const touchX = touch.clientX - left;
    const touchY = touch.clientY - top;

    const moveX = (touchX - width / 2) * -0.3; // 30% movement in opposite direction
    const moveY = (touchY - height / 2) * -0.3; // 30% movement in opposite direction

    container.style.transform = `translate(${moveX}px, ${moveY}px)`;
  };

  handleScroll = () => {
    if (!this.state.isMobile) return;
    const container = this.containerRef.current;
    const scrollPosition = window.scrollY;
    const moveY = -(scrollPosition * 0.3); // Adjust speed as needed

    container.style.transform = `translateY(${moveY}px)`;
  };


  render() {
    return (
      <div className='App'>
        <Navbar>
          <NavbarBrand>Mi sistemita</NavbarBrand>
          <NavLink href='#informacion' className='w-50'>Tutoriales</NavLink>
          <NavLink href='#contacto' className='w-50'>Accesos</NavLink>
          <NavLink href='/Creatuapp' className='w-50'>Crear App</NavLink>
        </Navbar>
        <header className='App-header'>
          <Card className='card-no-background'>
            <CardBody>
              <Row>
                <Col md={4}>
                  <div>
                    <h1>Mi sistemita</h1>
                    <p>Los CRM jamas habian sido tan sencillos
                      y adaptativos para el futuro como Mi
                      sistemita (TM) , el cual cuenta con la
                      escalabilidad del mañana, con una interfaz
                      abierta habre la opotunidad de que cada
                      quien le de su toque sistematico que
                      requiere , sin leguajes de programacion
                      mas intuitivo y accesible que nunca
                      rapido y fiable, enfocado a la Web3</p>
                  </div>
                </Col>
                <Col className='hover-container'
                  ref={this.containerRef}
                  onMouseMove={this.handleMouseMove}
                  onMouseLeave={this.handleMouseLeave}
                  onTouchMove={this.handleTouchMove}
                  md={7}>
                  <img src={this.state.smartPhone} className='hover-image' />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </header>
      </div>
    );
  }
}

