import SmartPhone from '../Assets/mockupsmart.png'
import NoImg from '../Assets/noimg.png'


const createSCID = (mainOff) => {
    let selectMainInfo = mainOff
    let seedTok = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
    let makeToken = ""
    seedTok.map(dates => {
        let min = 0
        let max = 9
        let rand = (Math.random() * (max - min)) + min
        makeToken += seedTok[rand.toFixed(0)]
    })
    let seedKey = ['a', 'b', 'c', 'd', 'e', 'f', 'g', '0', '1', '2', '3', '4', '5', '6', '7', 'D', 'A', 'B', 'T']
    let makeKey = ""
    seedKey.map(dates => {
        let min = 0
        let max = 18
        let rand = (Math.random() * (max - min)) + min
        makeKey += seedKey[rand.toFixed(0)]
        return makeKey
    })
    return { makeKey }
}

export const LoadIcons = {
    init: {
        displayMain: false,
        mainDisplay: 'display-block',
        pathUser: '',
    }
}

export const LoadApp = {
    init: {
        adminDisplay: 'display-none',
        alertState: 'danger',
        btnTxtTitle: ['Sample', 'Siguiente', 'Agregar', 'Cancelar', 'Nuevo', 'Continuar', 'Texto de Bienvenida', 'Terminar', 'Borrar'],
        btnToEnlist: 'Pedir',
        changeMainStep: true,
        choiseBtnColor: 'style-tn-a',
        choiseColorBackground: 'style-main-a',
        choiseStyleMain: 'c-items',
        cardBackground: 'style-card-back-a',
        configFrameStpZ: false,
        domains: ['misistemita.store/admin/', 'misistemita.store/monitor/', 'misistemita.store/'],
        enableFxFlame: 'display-none',
        enableFxText: 'display-none',
        enableMultiCard: 'display-none',
        frameOn: 'frame-left-sample',
        itemsList: false,
        itemImgSrc: NoImg,
        itemsMap: {},
        itemTags: [],
        itemsData: [],
        keyLicense: '',
        keyItem: [],
        lastStep: false,
        logoUrl: NoImg,
        logoImg: NoImg,
        nameTempItem: '',
        nameSite: '',
        newTag: '',
        noImg: NoImg,
        mainDisplay: 'display-block',
        menuToActive: 'none',
        metaObject: [],
        modalCreateTicket: false,
        modalAlert: false,
        modalItemsCard: false,
        modalSecureUser: false,
        modalWelcome: false,
        opBoolTitle: [true, false],
        pageState: ['0', '1', '2', '3', '4', '5', '6'],
        pageSelectorMain: true,
        pageExist: false,
        pointNameItem: 'Nombre del Item',
        pointDetailsItem: 'Descripcion del Item',
        pointItemSubcost: 0,
        pointItemSeudoCost: 0,
        pointItemTotalCost: 0,
        pointItemTitle: '',
        pointMonitor: {
            ticketsNow: [],
            aTicketsCC: [],
            aTicketsSS: [],
        },
        pointUpdateSet: false,
        pointUserGuess: false,
        pointGuessName: '',
        pointGuessItems: [],
        pointTotalClickGuess: 0,
        pointTicket: [],
        tags: [],
        transitioning: false,
        timestamp: '',
        totalClicks: 0,
        totalCost: 0,
        totalPeerItem: 0,
        txtCLABE: '',
        txtFxMsn: 'hot',
        txtTitleAlert: '',
        txtMainAlert: '',
        txtUserPass: '',
        txtModalWelcome: 'Ponerse en contacto con el admin',
        txtTraker: 'Identificador o traquer',
        txtMoreUserOn: 'Pedido organizado',
        txtTitle: ['Bienvenido', 'Seleccionar Estilo de inicio', 'Menu para levantar un pedido', 'Sitios y seguridad'],
        typeAppSelect: ['Ventas', 'Informativo',],
        sampleImg: NoImg,
        sideFrame: 'card-container-frame',
        showFirstDiv: true,
        shouldDisplayMain: false,
        secureUser: 'display-none',
        setColorDiv: 'style-divbtn-a',
        spaceMulticard: false,
        showCounter: false,
        stateToggleCheck: {},
        styleModal: ['top', 'bottom', 'center'],
        siteName: '',
        size_col: 6,
        sscid: createSCID().makeKey,
        utaStep: false,
        zeroItems: false,
    }
}

export const LoadApps = {
    init: {
        adminDisplay: 'display-none',
        alertState: 'danger',
        btnTxtTitle: ['Sample', 'Siguiente', 'Agregar', 'Cancelar', 'Nuevo', 'Continuar', 'Texto de Bienvenida', 'Terminar', 'Borrar'],
        btnToEnlist: 'Pedir',
        changeMainStep: true,
        choiseBtnColor: 'style-tn-a',
        choiseColorBackground: 'style-main-a',
        choiseStyleMain: 'c-items',
        cardBackground: 'style-card-back-a',
        configFrameStpZ: false,
        domains: ['misistemita.store/admin/', 'misistemita.store/monitor/', 'misistemita.store/'],
        enableFxFlame: 'display-none',
        enableFxText: 'display-none',
        enableMultiCard: 'display-none',
        frameOn: 'frame-left-sample',
        itemsList: false,
        itemImgSrc: NoImg,
        itemsMap: {},
        itemTags: [],
        itemsData: [],
        keyLicense: '',
        keyItem: [],
        lastStep: false,
        logoUrl: NoImg,
        logoImg: NoImg,
        nameTempItem: '',
        nameSite: '',
        newTag: '',
        noImg: NoImg,
        mainDisplay: 'display-block',
        menuToActive: 'none',
        metaObject: [],
        modalCreateTicket: false,
        modalAlert: false,
        modalItemsCard: false,
        modalSecureUser: false,
        modalWelcome: false,
        opBoolTitle: [true, false],
        pageState: ['0', '1', '2', '3', '4', '5', '6'],
        pageSelectorMain: true,
        pageExist: false,
        pointNameItem: 'Nombre del Item',
        pointDetailsItem: 'Descripcion del Item',
        pointItemSubcost: 0,
        pointItemSeudoCost: 0,
        pointItemTotalCost: 0,
        pointItemTitle: '',
        pointMonitor: {
            ticketsNow: [],
            aTicketsCC: [],
            aTicketsSS: [],
        },
        pointUpdateSet: false,
        pointUserGuess: false,
        pointGuessName: '',
        pointGuessItems: [],
        pointTotalClickGuess: 0,
        pointTicket: [],
        tags: [],
        transitioning: false,
        timestamp: '',
        totalClicks: 0,
        totalCost: 0,
        totalPeerItem: 0,
        txtCLABE: '',
        txtFxMsn: 'hot',
        txtTitleAlert: '',
        txtMainAlert: '',
        txtUserPass: '',
        txtModalWelcome: 'Ponerse en contacto con el admin',
        txtTraker: 'Identificador o traquer',
        txtMoreUserOn: 'Pedido organizado',
        txtTitle: ['Bienvenido', 'Seleccionar Estilo de inicio', 'Menu para levantar un pedido', 'Sitios y seguridad'],
        typeAppSelect: ['Ventas', 'Informativo',],
        sampleImg: NoImg,
        sideFrame: 'card-container-frame',
        showFirstDiv: true,
        shouldDisplayMain: false,
        secureUser: 'display-none',
        setColorDiv: 'style-divbtn-a',
        spaceMulticard: false,
        showCounter: false,
        stateToggleCheck: {},
        styleModal: ['top', 'bottom', 'center'],
        siteName: '',
        size_col: 6,
        sscid: createSCID().makeKey,
        utaStep: false,
        zeroItems: false,
    }
}

export const LoadCreateApp = {
    init: {
        adminDisplay: 'display-none',
        alertState: 'danger',
        btnTxtTitle: ['Sample', 'Siguiente', 'Agregar', 'Cancelar', 'Nuevo', 'Continuar', 'Texto de Bienvenida', 'Terminar', 'Borrar'],
        btnToEnlist: 'Pedir',
        changeMainStep: true,
        choiseBtnColor: 'style-tn-a',
        choiseColorBackground: 'style-main-a',
        choiseStyleMain: 'c-items',
        cardBackground: 'style-card-back-a',
        configFrameStpZ: false,
        currentInputItems: [],
        domains: ['misistemita.store/admin/', 'misistemita.store/monitor/', 'misistemita.store/'],
        enableFxFlame: 'display-none',
        enableFxText: 'display-none',
        enableMultiCard: 'display-none',
        frameOn: 'frame-left-sample',
        itemsList: false,
        itemImgSrc: NoImg,
        itemsMap: {},
        itemTags: [],
        itemsData: [],
        keyLicense: '',
        keyItem: [],
        lastStep: false,
        logoUrl: NoImg,
        logoImg: NoImg,
        nameTempItem: '',
        nameSite: '',
        newTag: '',
        noImg: NoImg,
        mainDisplay: 'display-block',
        menuToActive: 'none',
        metaObject: [],
        modalCreateTicket: false,
        modalAlert: false,
        modalItemsCard: false,
        modalSecureUser: false,
        modalWelcome: false,
        opBoolTitle: [true, false],
        pageState: ['0', '1', '2', '3', '4', '5', '6'],
        pageSelectorMain: true,
        pageExist: false,
        pointNameItem: 'Nombre del Item',
        pointDetailsItem: 'Descripcion del Item',
        pointItemSubcost: 0,
        pointItemSeudoCost: 0,
        pointItemTotalCost: 0,
        pointItemTitle: '',
        pointMonitor: {
            ticketsNow: [],
            aTicketsCC: [],
            aTicketsSS: [],
        },
        pointUpdateSet: false,
        pointUserGuess: false,
        pointGuessName: '',
        pointGuessItems: [],
        pointTotalClickGuess: 0,
        pointTicket: [],
        tags: [],
        transitioning: false,
        timestamp: '',
        totalClicks: 0,
        totalCost: 0,
        totalPeerItem: 0,
        txtCLABE: '',
        txtFxMsn: 'hot',
        txtTitleAlert: '',
        txtMainAlert: '',
        txtUserPass: '',
        txtModalWelcome: 'Ponerse en contacto con el admin',
        txtTraker: 'Identificador o traquer',
        txtMoreUserOn: 'Pedido organizado',
        txtTitle: ['Bienvenido', 'Seleccionar Estilo de inicio', 'Menu para levantar un pedido', 'Sitios y seguridad'],
        typeAppSelect: ['Ventas', 'Informativo',],
        sampleImg: NoImg,
        sideFrame: 'card-container-frame',
        showFirstDiv: true,
        shouldDisplayMain: false,
        secureUser: 'display-none',
        setColorDiv: 'style-divbtn-a',
        spaceMulticard: false,
        showCounter: false,
        stateToggleCheck: {},
        styleModal: ['top', 'bottom', 'center'],
        siteName: '',
        size_col: 6,
        sscid: createSCID().makeKey,
        utaStep: false,
        zeroItems: false,
    }
}

export const LoadProduction = {
    init: {
        adminDisplay: 'display-none',
        alertState: 'danger',
        btnTxtTitle: ['Sample', 'Siguiente', 'Agregar', 'Cancelar', 'Nuevo', 'Continuar', 'Texto de Bienvenida', 'Terminar', 'Borrar'],
        btnToEnlist: 'Pedir',
        changeMainStep: true,
        choiseBtnColor: 'style-tn-a',
        choiseColorBackground: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundImage: `linear-gradient(to bottom, #2d85d7, #0a0a0f)`
        },
        choiseStyleMain: 'c-items',
        cardBackground: 'style-card-back-a',
        configFrameStpZ: false,
        displayCharts: 'none',
        drawingColor: 'black', 
        domains: ['misistemita.store/admin/', 'misistemita.store/monitor/', 'misistemita.store/'],
        enableMainFX:false,
        enableFxFlame: 'display-none',
        enableFxText: 'display-none',
        enableMultiCard: 'display-none',
        enableTransfer: false,
        enableCardPay: false,
        frameOn: 'frame-left-sample',
        frameConfirmClose: false,
        itemsList: false,
        itemImgSrc: NoImg,
        itemsMap: {},
        itemTags: [],
        itemsData: [],
        isAnimating: false,   
        isErasing: false, 
        isValidUrl: false,
        keyLicense: '',
        keyItem: [],
        lastStep: false,
        logoUrl: NoImg,
        logoImg: NoImg,
        nameTempItem: '',
        nameSite: '',
        newTag: '',
        noImg: NoImg,
        mainDisplay: 'display-block',
        menuToActive: 'none',
        metaObject: [],
        modalCreateTicket: false,
        modalAlert: false,
        modalSetOffCanvas: false,
        modalItemsCard: false,
        modalStatusTicket: false,
        modalSecureUser: false,
        modalWelcome: false,
        opBoolTitle: [true, false],
        pageState: ['0', '1', '2', '3', '4', '5', '6'],
        pageSelectorMain: true,
        pageExist: false,
        pointNameItem: 'Nombre del Item',
        pointDetailsItem: 'Descripcion del Item',
        pointItemSubcost: 0,
        pointItemSeudoCost: 0,
        pointItemTotalCost: 0,
        pointItemTitle: '',
        pointItemLog: [],
        pointMonitor: {
            ticketsNow: [],
            aTicketsCC: [],
            aTicketsSS: [],
        },
        pointLogoImgBase64: '',
        pointGradientDirection: 'top',
        pointColorAlpha: '#7fc6d3',
        pointColorBetha: '#e6dea3',
        pointUpdateSet: false,
        pointUserGuess: false,
        pointGuessName: '',
        pointGuessItems: [],
        pointTotalClickGuess: 0,
        pointTicket: [],
        pointStatusTicket: NoImg,
        pointFaceUrl: 'https://facebook.com/...',
        rowStyleMld: {
            height: '284px',
            width: '330px',
            overflow: 'auto',
            flexWrap: 'wrap',
            alignItems: 'center',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
        },
        userCLABE: '',
        userPublicKey: '',
        userAccessToken: '',
        sampleImg: NoImg,
        sideFrame: 'card-container-frame',
        showFirstDiv: true,
        shouldDisplayMain: false,
        secureState: false,
        selectTicket: null,
        secureUser: 'display-none',
        setFavicon: '',
        setColorDiv: 'style-divbtn-a',
        spaceMulticard: false,
        showCounter: false,
        stateToggleCheck: {},
        styleModal: ['top', 'bottom', 'center'],
        siteName: '',
        size_col: 6,
        sscid: createSCID().makeKey,
        tags: [],
        transitioning: false,
        timestamp: '',
        totalClicks: 0,
        totalCost: 0,
        totalPeerItem: 0,
        txtCLABE: '',
        txtFxMsn: 'hot',
        txtTitleAlert: '',
        txtMainAlert: '',
        txtUserPass: '',
        txtModalWelcome: 'Ponerse en contacto con el admin',
        txtTraker: 'Identificador o traquer',
        txtMoreUserOn: 'Pedido organizado',
        txtTitle: ['Bienvenido', 'Seleccionar Estilo de inicio', 'Menu para levantar un pedido', 'Sitios y seguridad'],
        typeAppSelect: ['Ventas', 'Informativo',],
        currentFrame: 0,
        sprites: Array(5).fill(null), // Para almacenar 5 imágenes o dibujos
        strokeSize:1,
        // Color inicial de dibujo
     
        utaStep: false,
        zeroItems: false,
    }
}

export const LoadHomePage = {

    init: {
        smartPhone: SmartPhone,
        isMobile: false,
        keyLicense: '',
    }

}