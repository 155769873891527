import React, { Component } from 'react'
import { LoadProduction } from '../store/MetaStore';
import db from '../firebase';
import { doc, getDoc } from 'firebase/firestore';

export default class FrameTicketSts extends Component {
    constructor(props) {
        super(props)
        this.state = LoadProduction.init
    }

    async componentDidMount() {
        const { pathname } = window.location;
        const cleanedPathname = pathname.startsWith('/tickets/') ? pathname.substring('/tickets/'.length) : pathname;
        const license = await doc(db, '$:__sites-name', cleanedPathname)
        this.setState({ nameSite: cleanedPathname })
        if (cleanedPathname !== '') {
            const isLicenseAvalible = await getDoc(license)
            this.state = isLicenseAvalible.data()
            isLicenseAvalible.exists() ?
                this.setState({
                    ...isLicenseAvalible.data(),
                    pageExist: true
                }, () => { console.log(this.state) })
                :
                setTimeout(() => {
                    alert('este sitio no existe'); window.location = '/'
                }, 1500);
        }
    }
    render() {
        return (
            <div>FrameTicketSts</div>
        )
    }
}
