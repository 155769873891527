import jsPDF from 'jspdf';
import 'jspdf-autotable';

// Función para formatear la fecha y hora
const formatDate = (date) => {
  return date.toLocaleString();
};

const UserTicket = (data) => {
  const { siteName, txtUserPass, domains,  cardBackground,pointLogoImgBase64, timestamp, } = data;

  const doc = new jsPDF();

  // Añadir logo
  if (pointLogoImgBase64) {
    doc.addImage(pointLogoImgBase64, 'JPG', 10, 10, 50, 50);
  }

  // Título
  doc.setFontSize(18);
  doc.text('Datos de Configuración de la App', 70, 20);

  // Información del usuario y app
  doc.setFontSize(12);
  doc.text(`Nombre del Usuario: ${siteName}`, 10, 70);
  doc.text(`Contraseña: ${txtUserPass}`, 10, 80);
  doc.text(`Nombre de la Aplicación: ${siteName}`, 10, 90);
  doc.text(`Estilo Elegido: ${cardBackground}`, 10, 100);
  doc.text(`Fecha y Hora: ${formatDate(new Date(timestamp))}`, 10, 110);

  // Dominios
  doc.text('Dominios:', 10, 120);
  domains.forEach((domain, index) => {
    doc.text(`- ${domain+siteName}`, 20, 130 + index * 10);
  });

  // Guardar el PDF
  doc.save(`${siteName}_configuracion.pdf`);
};

export default UserTicket;
