import React, { Component } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { FaChartSimple, FaDisplay, FaGear, FaMoneyCheckDollar } from 'react-icons/fa6';
import LoadAdmin from './LoadAdmin';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import db from '../firebase';
import {  LoadProduction } from '../store/MetaStore';
import FrameAdmin from './FrameAdmin';
import FrameCharts from './Common/FrameCharts';
import FramePays from './FramePays';

export default class LoadMain extends Component {
    constructor(props) {
        super(props);
        this.state = LoadProduction.init
    }

    componentDidMount() {
        this.loadSiteData();
    }

    cleanPathname() {
        const { pathname } = window.location;
        return pathname.startsWith('/admin/') ? pathname.substring('/admin/'.length) : pathname;
    }

    async loadSiteData() {
        const cleanedPathname = this.cleanPathname();
        const license = doc(db, '$:__sites-name', cleanedPathname);

        this.setState({ nameSite: cleanedPathname });

        if (cleanedPathname) {
            const isLicenseAvailable = await getDoc(license);
            if (isLicenseAvailable.exists()) {
                this.setState({
                    ...isLicenseAvailable.data(),
                    pageExist: true,
                });
            } else {
                setTimeout(() => {
                    alert('Este sitio no existe');
                    window.location = '/';
                }, 1500);
            }
        }
    }

    toggleDisplay = (type) => {
        if (type === 'admin') {
            this.setState({ displayCharts: 'admin' })
        }
        if (type === 'main') {
            this.setState({ displayCharts: 'main' })
        }
        if (type === 'chart') {
            this.setState({ displayCharts: 'chart' })
        }
        if (type === 'pays') {
            this.setState({ displayCharts: 'pays' })
        }
        if (type === 'none') {
            this.setState({ displayCharts: 'none' })
        }
    }


    render() {
        const { adminDisplay, mainDisplay, displayCharts, pathUser } = this.state;

        return (
            <div className="App-header">
                <Card className={mainDisplay}>
                    <Row>
                        <Col>
                            <FaGear className="c-pointer z-25" onClick={() => this.toggleDisplay('admin')} />
                        </Col>
                        <Col>
                            <FaDisplay className="c-pointer" onClick={() => this.toggleDisplay('main')} />
                        </Col>
                        <Col>
                            <FaChartSimple className="c-pointer" onClick={() => this.toggleDisplay('chart')} />
                        </Col>
                        <Col>
                        <FaMoneyCheckDollar className="c-pointer" onClick={() => this.toggleDisplay('pays')}  />                        
                        </Col>
                    </Row>
                </Card>

                {displayCharts === 'admin' ? (
                    <FrameAdmin config={this.state} onCrossBtn={() => this.toggleDisplay('none')} />
                ) : displayCharts === 'main' ? (
                    <LoadAdmin onLoadComplete={this.handleDoneInPage} page={pathUser} onCrossBtn={() => this.toggleDisplay('none')} />
                ) : displayCharts === 'chart' ? (
                    <FrameCharts nameSite={pathUser} onCrossBtn={() => this.toggleDisplay('none')}/>
                ) :  displayCharts === 'pays' ? (
                    <FramePays nameSite={pathUser} onCrossBtn={() => this.toggleDisplay('none')}/>
                ) : null}
            </div>
        );
    }
}
