import React, { Component } from 'react'
import { LoadApps } from '../store/MetaStore'
import { Button, Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Form, FormControl, InputGroup, Row } from 'react-bootstrap';
import { FaCircleChevronLeft, FaSquareFacebook } from 'react-icons/fa6';
import { Firestore, collection, doc, getDoc, getDocs, setDoc, updateDoc } from 'firebase/firestore'
import { getDownloadURL, ref, uploadBytes, uploadBytesResumable } from 'firebase/storage'
import db, { storage } from '../firebase';
import AlertMsn from './Common/AlertMsn';

export default class FrameAdmin extends Component {
    constructor(props) {
        super(props)
        this.state = this.props.config
        this.handleCloseAlert = this.handleCloseAlert.bind(this);
        this.handleChange = this.handleChange.bind(this)
    }

    handleBackButtonClick = () => {

        if (this.props.onCrossBtn) {
            this.props.onCrossBtn();
            console.log('push')
        } else {
            console.error("E-701:Sistema crusado en falla");
        }
    };

    async componentDidMount() {
        
        const { pathname } = window.location;
        const cleanedPathname = pathname.startsWith('/admin/') ? pathname.substring('/admin/'.length) : pathname;
        const license = await doc(db, '$:__sites-name', cleanedPathname)
        this.setState({ nameSite: cleanedPathname })
        if (cleanedPathname !== '') {
            const isLicenseAvalible = await getDoc(license)
            this.state = isLicenseAvalible.data()
            isLicenseAvalible.exists() ?
                this.setState({
                    ...isLicenseAvalible.data(),
                    pageExist: true
                },()=>{this.updateFavicon()})
                :
                setTimeout(() => {
                    alert('este sitio no existe'); window.location = '/'
                }, 1500);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.modalAlert && !prevState.modalAlert) {
            this.autoCloseTimer = setTimeout(() => {
                this.handleCloseAlert();
            }, 1450);
        }

        if (!this.state.modalAlert && prevState.modalAlert) {
            clearTimeout(this.autoCloseTimer);
        }
    }

    componentWillUnmount() {
        clearTimeout(this.autoCloseTimer);
    }

    async handleNewItem() {
        const { modalAlert, txtMainAlert, txtTitleAlert, alertState, ...dataToSave } = this.state;


        this.setState({
            displayCharts: 'none',
            choiseColorBackground: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundImage: `linear-gradient(to ${this.state.pointGradientDirection}, ${this.state.pointColorAlpha}, ${this.state.pointColorBetha})`
            }
        }, async () => {

            try {
                console.log('Datos reales:', this.state);
                await updateDoc(doc(db, '$:__sites-name', this.state.siteName), this.state);

                this.setState({
                    modalAlert: true,
                    txtMainAlert: 'Configuración emparejada con el servidor',
                    txtTitleAlert: 'Mi sistemita',
                    alertState: 'success',
                });
            } catch (e) {
                this.setState({
                    modalAlert: true,
                    txtMainAlert: 'Error al emparejar con el servidor',
                    txtTitleAlert: 'Mi sistemita',
                    alertState: 'danger',
                });
            }
        });
    }


    handleDirectionChange = (e) => {
        this.setState({ pointGradientDirection: e.target.value });
    }

    handleColor1Change = (e) => {
        this.setState({ pointColorAlpha: e.target.value });
    }

    handleColor2Change = (e) => {
        this.setState({ pointColorBetha: e.target.value });
    }


    handleInputChange = (event) => {
        const newValue = event.target.value;
        this.setState((prevState) => {
            const updatedBtnTxtTitle = [...prevState.btnTxtTitle];
            updatedBtnTxtTitle[6] = newValue;
            return { btnTxtTitle: updatedBtnTxtTitle };
        });
    }

    handleCloseAlert() {
        this.setState({ modalAlert: false });
    };

    handleChange(e) {
        const inputUrl = e.target.value;
        const regex = /^(https?:\/\/)?(www\.)?(facebook\.com)\/[\w\-]+$/;

        console.log(inputUrl)
        // Validar si el URL es de Facebook
        if (regex.test(inputUrl)) {
            this.setState({ isValidUrl: true, pointFaceUrl: inputUrl }); // URL de Facebook válido
        } else {
            this.setState({ isValidUrl: false }); // URL no válido
        }
    };

    
    async handleUrlTitle() {
        const loadResurses = () => {
            
            try {
                getDownloadURL(ref(storage, 'items/Thumbs/' + this.state.siteName + '/logo.jpg'))
                    .then((url) => {
                        this.setState({
                            logoImg: url,
                            setFavicon: url,
                        },async ()=>{

                            const newFavicon = document.createElement('link');
                            newFavicon.id = 'favicon';
                            newFavicon.rel = 'icon';
                            newFavicon.href = this.state.setFavicon;
                            document.head.appendChild(newFavicon);         
                        })
                    }).catch((error) => {
                        console.log(error)
                    })
            } catch (e) {
                console.log(e)
            }           
            
        }
        loadResurses()
    }

    async handleTitleImg(c) {
        const reader = new FileReader();
        let fileData = c.target.files[0]
        reader.onloadend = () => {
            this.setState({ setFavicon: reader.result }, this.updateFavicon(reader.result));
          };      
          if (fileData) {
            reader.readAsDataURL(fileData); 
          }
        const storageRef = ref(storage, 'items/Thumbs/' + this.state.siteName + '/logo.jpg');
        uploadBytes(storageRef, fileData).then((snapshot) => {
            this.handleUrlTitle()
        })  
            
    }

    updateFavicon = (faviconUrl) => {      
        let favicon = document.getElementById('favicon');        
        if (favicon) {        
          favicon.href = faviconUrl;
          console.log(favicon)
        } else {          
          const newFavicon = document.createElement('link');
          console.log( newFavicon)
          newFavicon.id = 'favicon';
          newFavicon.rel = 'icon';
          newFavicon.href = this.state.setFavicon;
          document.head.appendChild(newFavicon);
        }
      };

    crossBtnItemStore() {
        let putImg = document.querySelectorAll('#itemImg')
        putImg[0].click()
    }


    render() {
        const choiseColorBackground = {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundImage: `linear-gradient(to ${this.state.pointGradientDirection}, ${this.state.pointColorAlpha}, ${this.state.pointColorBetha})`
        };
        return (
            <div>
                <AlertMsn
                    modalAlert={this.state.modalAlert}
                    alertState={this.state.alertState}
                    txtTitleAlert={this.state.txtTitleAlert}
                    txtMainAlert={this.state.txtMainAlert}
                    handleCloseAlert={this.handleCloseAlert}
                />
                <Card>
                    <CardBody>
                        < FaCircleChevronLeft className=' z-10' onClick={() => { this.handleBackButtonClick() }} />
                        <CardHeader>
                            {this.state.nextInsideFrame === false ?
                                <CardTitle>{this.state.txtTitle[1]}</CardTitle>
                                : this.state.lastStep === false ?
                                    <CardTitle>{this.state.txtTitle[2]}</CardTitle> :
                                    <CardTitle>{this.state.txtTitle[3]}</CardTitle>}
                        </CardHeader>
                        <Row>
                            <Col>
                                {/*-----------------------------------*/}
                                <Card style={choiseColorBackground} >
                                    <CardBody>
                                        <div className={`frame-left-sample ${this.state.choiseStyleMain}`}>
                                            <Button className={`${this.state.choiseBtnColor}`}>{this.state.btnTxtTitle[6]}</Button>
                                            {this.state.isValidUrl ? (
                                                <div style={{ bottom: '0', color: 'blue', fontSize: '24px', position: 'absolute' }}>
                                                    <FaSquareFacebook />
                                                </div>
                                            ) : (
                                                <p style={{ bottom: '0', color: 'red', fontSize: '12px', position: 'absolute' }}>Por favor ingrese un URL válido de Facebook</p>
                                            )}
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col>
                                <Card className='change-main-a'>
                                    <CardBody>
                                        <InputGroup>
                                            <FormControl
                                                placeholder='Cambiar Texto de bienvenida'
                                                onChange={this.handleInputChange}
                                            />
                                        </InputGroup>
                                    </CardBody>
                                </Card>

                                <Card className='change-main-a'>
                                    <CardTitle><h6>Cambiar Color de boton</h6></CardTitle>
                                    <CardBody>
                                        < div style={{ display: 'inline-flex', cursor: 'pointer' }}>
                                            <div onClick={() => { this.setState({ choiseBtnColor: 'style-btn-a' }) }} className='style-divbtn-a'></div>
                                            <div onClick={() => { this.setState({ choiseBtnColor: 'style-btn-b' }) }} className='style-divbtn-b'></div>
                                            <div onClick={() => { this.setState({ choiseBtnColor: 'style-btn-c' }) }} className='style-divbtn-c'></div>
                                        </div>
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardBody>
                                        <label>Ingrese el vínculo de Facebook:</label>
                                        <input
                                            type="text"
                                            onChange={this.handleChange}
                                            placeholder={this.state.pointFaceUrl}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col> <div className='c-items' >
                                <Form className='w-270' >
                                    {['radio'].map((type) => (
                                        <div key={`inline-${type}`} className="mb-3">
                                            <Form.Check
                                                inline
                                                label="1"
                                                name="group1"
                                                type={type}
                                                id={`inline-${type}-1`}
                                                defaultChecked={false}
                                                onChange={() => { this.setState({ choiseStyleMain: 'b-items' }) }}
                                            />
                                            <Form.Check
                                                inline
                                                label="2"
                                                name="group1"
                                                type={type}
                                                id={`inline-${type}-2`}
                                                defaultChecked={true}
                                                style={{ marginLeft: '5rem' }}
                                                onChange={() => { this.setState({ choiseStyleMain: 'c-items' }) }}
                                            />
                                        </div>
                                    ))}
                                </Form>
                            </div>
                                <CardTitle>Area de fondo de pantalla</CardTitle>
                                <CardBody>
                                    <div>
                                        <label>
                                            Dirección de luz:
                                            <select value={this.state.pointGradientDirection} onChange={this.handleDirectionChange}>
                                                <option value="top">Cielo</option>
                                                <option value="bottom">Suelo</option>
                                                <option value="left">Izq</option>
                                                <option value="right">Der</option>
                                            </select>
                                        </label>
                                    </div>

                                    <div>
                                        <label>
                                            Color 1:
                                            <input
                                                type="color"
                                                value={this.state.pointColorAlpha}
                                                onChange={this.handleColor1Change}
                                            />
                                        </label>
                                    </div>

                                    <div>
                                        <label>
                                            Color 2:
                                            <input
                                                type="color"
                                                value={this.state.pointColorBetha}
                                                onChange={this.handleColor2Change}
                                            />
                                        </label>
                                    </div>
                                </CardBody>
                            </Col>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <CardHeader>Cargar Logo</CardHeader>
                                        <img src={this.state.logoImg} width={'20%'} onClick={() => { this.crossBtnItemStore() }} />

                                        <Form.Control
                                            style={{ display: 'none' }}
                                            id='itemImg'
                                            type='file'
                                            accept='image/png,image/jpg'
                                            onChange={(e) => this.handleTitleImg(e)}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter>
                        <Button onClick={() => { this.handleNewItem() }}>Aplicar Cambios </Button>
                    </CardFooter>
                </Card>
            </div>
        )
    }
}
