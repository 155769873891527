import React, { Component } from 'react';
import { Card, CardBody, InputGroup, FormControl, Button, Form, Row, Col, Badge, Modal } from 'react-bootstrap';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import db, { storage } from '../firebase';
import NoImg from '../Assets/noimg.png';
import MercadoPagoBtn from './Common/Mercadopagobtn';

export default class CreateAppUser extends Component {
    constructor(props) {
        super(props);
        this.state = this.props.config;
        this.handleAddInput = this.handleAddInput.bind(this)
        this.handleLoadFile = this.handleLoadFile.bind(this)
        this.mercadoPagoBtnRef = React.createRef();
        this.handleModalClose = this.handleModalClose.bind(this)
    }

    generateNewSSCID = () => {
        const createSCID = () => {
            let seedKey = ['a', 'b', 'c', 'd', 'e', 'f', 'g', '0', '1', '2', '3', '4', '5', '6', '7', 'D', 'A', 'B', 'T'];
            let makeKey = '';
            seedKey.forEach(() => {
                let min = 0;
                let max = 18;
                let rand = (Math.random() * (max - min)) + min;
                makeKey += seedKey[Math.floor(rand)];
            });
            return { makeKey };
        };

        const newSSCID = createSCID().makeKey;
        this.setState({ sscid: newSSCID });
        console.log(newSSCID)
    };

    componentDidMount() {
        this.setState({ frameOn: '' });
    }


    toggleCheck = (checkId) => {
        this.setState((prevState) => ({
            selectedCheck: prevState.selectedCheck === checkId ? null : checkId,
            currentName: '',
            stateToggleCheck: checkId === 1 ? {} : prevState.stateToggleCheck,
        }), async () => {
            this.setState({
                metaObject: []
            })
        });
    }

    handleItemClick = (key) => {
        this.setState((prevState) => {
            const updatedItem = {
                ...prevState.itemsMap[key],
                clicks: prevState.itemsMap[key].clicks + 1,
                showCounter: true,
                totalPeerItem: prevState.itemsMap[key].clicks * prevState.itemsMap[key].priceItem,
            };
            const updatedItemsMap = {
                ...prevState.itemsMap,
                [key]: updatedItem,
            };

            return {
                itemsMap: updatedItemsMap,
            };
        }, () => {
            const { itemsMap } = this.state;
            const selectedItems = Object.entries(itemsMap).filter(([key, item]) => item.clicks > 0);
            const totalCost = selectedItems.reduce((total, [, item]) => total + (item.clicks * item.priceItem), 0);
            this.setState({
                totalCost
            })
        });
    };

    handleResetCounter = (key) => {
        this.setState((prevState) => {
            const updatedItem = {
                ...prevState.itemsMap[key],
                clicks: 0,
                showCounter: false,
            };
            const updatedItemsMap = {
                ...prevState.itemsMap,
                [key]: updatedItem,
            };
            const totalCost = this.calculateTotalCost(updatedItemsMap);

            return {
                itemsMap: updatedItemsMap,
                totalCost,
            };
        });
    };

    restarTotalCost = (itemsMap) => {
        return Object.values(itemsMap).reduce((total, item) => {
            return total + item.priceItem * item.clicks;
        }, 0);
    };

    calculateTotalCost = (itemsMap) => {
        return Object.values(itemsMap).reduce((total, item) => {
            return total + (item.priceItem * item.clicks);
        }, 0);
    };

    handleNameChange = (e) => {
        this.setState({ pointGuessName: e.target.value });
    };


    async handleAddInput() {
        const { pointGuessName, itemsMap, selectedCheck } = this.state;

        const selectedItems = Object.entries(itemsMap).filter(([key, item]) => item.clicks > 0);

        const itemsData = selectedItems.map(([key, item]) => ({
            keyItem: key,
            clicks: item.clicks,
            priceItem: item.priceItem,
            totalPeerItem: item.clicks * item.priceItem
        }));

        const totalPeerCost = selectedItems.reduce((total, [, item]) => total + (item.clicks * item.priceItem), 0);
        const totalClicks = selectedItems.reduce((total, [, item]) => total + item.clicks, 0);

        this.setState((prevState) => {
            const newTotalCost = prevState.totalCost + totalPeerCost;
            const newItemsMap = { ...prevState.itemsMap };
            selectedItems.forEach(([key]) => {
                newItemsMap[key].clicks = 0;
            });

            const keyItems = itemsData.map(item => item.keyItem);
            console.log(keyItems);

            return {
                metaObject: {
                    ...prevState.metaObject,
                    items: [...(prevState.metaObject.items || []), { name: pointGuessName, cuantos: totalClicks, total: totalPeerCost, keyItems: itemsData }],
                },
                pointUserGuess: true,
                pointGuessName: '',
                totalCost: newTotalCost,
                itemsMap: newItemsMap,
            };
        }, () => {
            let newTotalCost = 0
            this.setState((prevState) => {
                if (selectedCheck === 1) {
                    newTotalCost = Object.values(prevState.itemsMap).reduce((total, item) => {
                        return total + (item.clicks > 0 ? item.clicks * item.priceItem : 0);
                    }, 0);
                } else if (selectedCheck === 2) {
                    newTotalCost = prevState.metaObject.items.reduce((total, item) => {
                        return total + item.total;
                    }, 0);
                }

                return {
                    totalCost: newTotalCost,
                };
            });
        });
        document.getElementById('guess-name').value = '';
    }



    handleModalClose() {
        this.setState({
            modalCreateTicket: false
        })
    }

    handleRemoveGuess = (index) => {
        console.log(index)
        this.setState((prevState) => {
            const { metaObject, itemsMap } = prevState;

            if (!metaObject || !metaObject.items || metaObject.items.length <= index) {
                console.warn('metaObject o items no disponibles, o índice fuera de rango');
                return prevState;
            }

            const itemToRemove = metaObject.items[index];
            const updatedItems = metaObject.items.filter((_, i) => i !== index);

            const costToSubtract = itemToRemove.keyItems.reduce((total, item) => {
                return total + item.totalPeerItem;
            }, 0);

            const updatedTotalCost = prevState.totalCost - costToSubtract;

            return {
                metaObject: {
                    ...metaObject,
                    items: updatedItems,
                },
                pointUserGuess: updatedItems.length > 0,
                totalCost: updatedTotalCost,
            };
        });
    };

    handleLastStep() {
        this.setState({
            modalCreateTicket: true
        })
    }

    randomDelay = (min = 100, max = 1200) => {
        const delay = Math.floor(Math.random() * (max - min + 1)) + min;
        return new Promise(resolve => setTimeout(resolve, delay));
    };

    handleLoadFile = async () => {
      await   this.generateNewSSCID();
        console.log(this.generateNewSSCID())
        console.log(this.state.sscid)
        try {
            
            const { metaObject, itemsMap, siteName, txtTraker, sscid, totalCost } = this.state;
            const docRef = doc(db, '$:__site-monitor', siteName);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                const dataFromFirestore = docSnap.data();

                this.setState({ pointMonitor: dataFromFirestore.pointMonitor }, async () => {

                    await this.randomDelay();

                    const newTicket = {
                        status: 'pendiente a pago',
                        data: metaObject && Object.keys(metaObject).length > 0 ? metaObject : (itemsMap && Object.keys(itemsMap).length > 0 ? itemsMap : 'Sin datos disponibles'),
                        nameId: txtTraker,
                        idTicket: sscid,
                        totalCost: totalCost,
                    };
                    this.setState(prevState => ({
                        pointMonitor: {
                            ...prevState.pointMonitor,
                            ticketsNow: [...prevState.pointMonitor.ticketsNow, newTicket],
                        }
                    }), async () => {

                        await updateDoc(doc(db, '$:__site-monitor', this.state.siteName), { pointMonitor: this.state.pointMonitor });
                        console.log('Nuevo ticket añadido:', newTicket);
                        console.log('Estado actualizado de monitor:', this.state.pointMonitor);
                        this.setState({
                            metaObject: []
                        });
                        if (this.mercadoPagoBtnRef.current) {
                            this.mercadoPagoBtnRef.current.triggerPayment();
                        }
                    });
                });


            } else {
                console.error('No se encontraron datos en la colección de Firebase.');
            }
        } catch (error) {
            console.error('Error al obtener o actualizar los datos:', error);
        }
    };


    render() {
        const { cardBackground, txtTraker, enableMultiCard, itemsMap, metaObject, modalCreateTicket, txtCLABE, sideFrame } = this.state;

        const itemsWithClicks = Object.values(itemsMap).filter(item => item.clicks > 0);

        const subtotal = itemsWithClicks.reduce((total, item) => total + item.totalPeerItem, 0);


        return (
            <>
                <Card className={cardBackground + ' ' + sideFrame}>
                    <CardBody>
                        <InputGroup>
                            <FormControl
                                placeholder={txtTraker}
                                onChange={(e) => {
                                    (this.setState({
                                        txtTraker: e.target.value
                                    }))
                                }}
                            />
                        </InputGroup>
                    </CardBody>
                    <CardBody className={enableMultiCard}>
                        <div className="check-container">
                            <Form.Check
                                type="checkbox"
                                label="Individual"
                                checked={this.state.selectedCheck === 1}
                                onChange={() => this.toggleCheck(1)}
                            />

                        </div>
                        <div className="check-container">
                            <Form.Check
                                type="checkbox"
                                label="Separado"
                                checked={this.state.selectedCheck === 2}
                                onChange={() => this.toggleCheck(2)}
                            />
                        </div>
                        <CardBody>
                            {this.state.selectedCheck === 2 && (
                                <InputGroup>
                                    <Button onClick={this.handleAddInput}>Agregar a:</Button>
                                    <FormControl
                                        placeholder="Este para quien"
                                        id='guess-name'
                                        value={this.state.pointGuessName}
                                        onChange={this.handleNameChange}
                                    />
                                </InputGroup>
                            )}
                        </CardBody>
                    </CardBody>
                    <CardBody>
                        <Row style={this.state.rowStyleMld}>
                            {Object.entries(this.state.itemsMap).map(([key, e], k) => (
                                <Col xs={this.state.size_col} key={k}>
                                    <div
                                        onClick={() => { this.handleItemClick(key); }}
                                        className="glass-effect c-pointer text-center"
                                        key={k}
                                    >
                                        <img className='img-card-no' src={e.src || NoImg} alt='no-img.png' width={'100%'} />
                                        <div className={`${e.enableFxFlame || ''} flame-container`}>
                                            <svg className="flame" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                                                <path fill="#FF5722" d="M32,4C32,4,2,24,8,46c3.889,14.11,24,18,24,18s20.111-3.89,24-18C62,24,32,4,32,4z" />
                                                <path fill="#FF7043" d="M32,14c0,0-14.4,10.6-9.6,26.4C25.6,46.4,32,48,32,48s6.4-1.6,9.6-7.6C46.4,24.6,32,14,32,14z" />
                                                <path fill="#FF8A65" d="M32,26c0,0-5.3,3.9-3.2,10.8C30.1,40.3,32,41,32,41s1.9-0.7,3.2-4.2C37.3,29.9,32,26,32,26z" />
                                            </svg>
                                        </div>
                                        <div className={`${e.enableFxText || ''} text-container`}>
                                            <span className="neon-text">{e.txtFxMsn}</span>
                                        </div>
                                        {e.showCounter && (
                                            <div className="position-absolute bottom-0 start-0 p-3 bg-transparent w-100 h-100">
                                                <button
                                                    className='position-absolute top-0 right-0 btn btn-danger'
                                                    onClick={(event) => { event.stopPropagation(); this.handleResetCounter(key); }}
                                                >
                                                    X
                                                </button>
                                                <div className='position-absolute bottom-0 left-0'>{`Van: ${e.clicks}`}</div>
                                            </div>
                                        )}
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </CardBody>
                    {this.state.selectedCheck === 2 && (
                        <CardBody className='card-frame-tags'>
                            {this.state.pointUserGuess === true ? Array.isArray(metaObject.items) && metaObject.items.map((name, index) => (
                                <Badge key={index} pill bg="primary" className="me-2 m-w-5">
                                    {name.name} : {name.cuantos} : {"$" + name.total}.
                                    <Button variant="link" size="sm" onClick={() => this.handleRemoveGuess(index)}>X</Button>
                                </Badge>
                            )) : <></>}
                        </CardBody>
                    )}
                    <CardBody>
                        <h3>Total: ${this.state.totalCost}</h3>
                    </CardBody>
                    <CardBody className={this.state.secureUser}>
                        <InputGroup>
                            <FormControl
                                placeholder='Numero de contacto, etc.'
                                onChange={(e) => this.setState({ btnToEnlist: e.target.value })}
                            />
                        </InputGroup>
                    </CardBody>
                    <CardBody>
                        <Button id='done' onClick={() => { this.handleLastStep(); }}>ENLISTAR</Button>
                    </CardBody>
                </Card>
                <Modal show={modalCreateTicket} onHide={this.handleModalClose} className='txt-white'>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.txtTraker}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <hr />
                            {Array.isArray(this.state.metaObject.items) && this.state.metaObject.items.length > 0 ? (
                                this.state.metaObject.items.map((item, index) => (
                                    <div key={index} style={{ fontSize: '12px', maxHeight: '50px' }}>
                                        <strong>El de : </strong> {item.name} <br />
                                        <strong>Uds:</strong> {item.cuantos} <br />
                                        <strong>sub-total:</strong> ${item.total.toFixed(2)} <br />
                                        <hr />
                                    </div>
                                ))
                            ) : (
                                <></>
                            )}
                            {itemsWithClicks.length > 0 ? (
                                itemsWithClicks.map((item, index) => (
                                    <div key={index} style={{ fontSize: 'small', height: '50px' }}>
                                        {item.name} : {item.clicks} x ${item.priceItem}
                                    </div>
                                ))
                            ) : (<></>

                            )}
                            <hr />
                            <div style={{ fontSize: '14px', textAlign: 'right' }}>

                                <strong>Total:</strong> ${this.state.totalCost.toFixed(2)} <br />
                            </div>
                            <hr />

                            {/* Información de pago */}
                            <div style={{ fontSize: '14px', textAlign: 'center' }}>
                                Pagar a esta cuenta: {txtCLABE}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button disabled={this.state.enableCardPay} variant="primary" onClick={this.handleLoadFile}>
                            Pagar con tarjeta
                        </Button>

                        <Button variant="secondary" onClick={this.handleModalClose}>
                            Cancelar
                        </Button><p>o</p>
                        <hr />
                        <MercadoPagoBtn
                            ref={this.mercadoPagoBtnRef}
                            nameId={this.state.txtTraker}
                            itemMap={this.state.itemMap}
                            metaObject={this.state.metaObject}
                            ssuid={this.state.sscid}
                            nameApp={this.state.nameSite}
                            totalCost={this.state.totalCost}
                            publicKey={this.state.userPublicKey}
                            accessToken={this.state.userAccessToken}
                        />
                        <Button disabled={true} variant="primary" onClick={this.handleLoadFile}>
                            Pagar con efectivo
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}