import React, { Component } from 'react';
import { FaEraser, FaPaintBrush } from 'react-icons/fa'; // Iconos de react-icons
import { SketchPicker } from 'react-color'; // Para el selector de color
import { Card, CardBody, Col, Row } from 'react-bootstrap';
import { LoadProduction } from '../../store/MetaStore';

class FXmaker extends Component {
  constructor(props) {
    super(props);
    this.state = LoadProduction.init
    this.canvasRef = React.createRef();
    this.imageInputRef = React.createRef();
    this.isDrawing = false; // Estado para saber si el usuario está dibujando
  }

  componentDidMount() {
    const canvas = this.canvasRef.current;
    const ctx = canvas.getContext('2d');
    this.ctx = ctx;

    // Añadir los eventos de dibujo continuo
    canvas.addEventListener('mousedown', this.handleMouseDown);
    canvas.addEventListener('mousemove', this.handleMouseMove);
    canvas.addEventListener('mouseup', this.handleMouseUp);
    canvas.addEventListener('mouseleave', this.handleMouseUp); // Para detener dibujo si se sale del canvas

    // Añadir eventos táctiles
    canvas.addEventListener('touchstart', this.handleTouchStart);
    canvas.addEventListener('touchmove', this.handleTouchMove);
    canvas.addEventListener('touchend', this.handleTouchEnd);
    canvas.addEventListener('touchcancel', this.handleTouchEnd); // Para detener dibujo si se cancela
  }

  // Funciones para el dibujo con mouse
  handleMouseDown = (e) => {
    this.isDrawing = true;
    this.draw(e);
  };

  handleMouseMove = (e) => {
    if (!this.isDrawing) return;
    this.draw(e);
  };

  handleMouseUp = () => {
    this.isDrawing = false;
  };

  // Funciones para el dibujo con toque
  handleTouchStart = (e) => {
    e.preventDefault(); // Evita el desplazamiento de la página
    this.isDrawing = true;
    this.drawTouch(e);
  };

  handleTouchMove = (e) => {
    if (!this.isDrawing) return;
    this.drawTouch(e);
  };

  handleTouchEnd = () => {
    this.isDrawing = false;
  };

  handleFrameSelection = (index) => {
    const { sprites } = this.state;
    this.setState({ currentFrame: index });
    const ctx = this.ctx;
    ctx.clearRect(0, 0, 64, 64); // Limpia el canvas
    if (sprites[index]) {
      ctx.putImageData(sprites[index], 0, 0); // Muestra el frame seleccionado en el canvas principal
    }
  };

  // Dibuja o borra un píxel (para mouse)
  draw = (e) => {
    const ctx = this.ctx;
    const rect = this.canvasRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    if (this.state.isErasing) {
      ctx.clearRect(x, y, 1, 1); // Borra el píxel (hace transparente)
    } else {
      ctx.fillStyle = this.state.drawingColor; // Dibuja con el color seleccionado
      ctx.fillRect(x, y, this.state.strokeSize, this.state.strokeSize)
    }

    // Actualiza el frame actual en el estado
    const { currentFrame, sprites } = this.state;
    const updatedSprites = [...sprites];
    updatedSprites[currentFrame] = ctx.getImageData(0, 0, 64, 64);
    this.setState({ sprites: updatedSprites });
  };

  // Dibuja o borra un píxel (para táctil)
  drawTouch = (e) => {
    const ctx = this.ctx;
    const rect = this.canvasRef.current.getBoundingClientRect();
    const touch = e.touches[0]; // Solo toma el primer toque
    const x = touch.clientX - rect.left;
    const y = touch.clientY - rect.top;

    if (this.state.isErasing) {
      ctx.clearRect(x, y, 1, 1); // Borra el píxel
    } else {
      ctx.fillStyle = this.state.drawingColor; // Dibuja con el color seleccionado
      ctx.fillRect(x, y, 1, 1); // Dibuja un píxel
    }

    // Actualiza el frame actual en el estado
    const { currentFrame, sprites } = this.state;
    const updatedSprites = [...sprites];
    updatedSprites[currentFrame] = ctx.getImageData(0, 0, 64, 64);
    this.setState({ sprites: updatedSprites });
  };

  handleSaveSprite = () => {
    const { currentFrame, sprites } = this.state;
    const updatedSprites = [...sprites];
    updatedSprites[currentFrame] = this.ctx.getImageData(0, 0, 64, 64);
    this.setState({ sprites: updatedSprites }, () => {
      alert(`Sprite guardado en el frame ${currentFrame}.`);

      // Limpiar el canvas después de guardar
      this.ctx.clearRect(0, 0, 64, 64);

      // Mover al siguiente cuadro si hay espacio
      if (currentFrame < 4) {
        this.setState({ currentFrame: currentFrame + 1 });
      } else {
        alert('Has llegado al último frame.');
      }
    });
  };

  handleLoadImage = () => {
    const file = this.imageInputRef.current.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        const ctx = this.ctx;
        ctx.clearRect(0, 0, 64, 64); // Limpia el canvas antes de cargar la imagen
        ctx.drawImage(img, 0, 0, 64, 64); // Dibuja la imagen en el canvas

        // Guarda la imagen en el frame actualmente seleccionado
        const { currentFrame, sprites } = this.state;
        const updatedSprites = [...sprites];
        updatedSprites[currentFrame] = ctx.getImageData(0, 0, 64, 64); // Guarda el frame
        this.setState({ sprites: updatedSprites }, () => {
          alert(`Imagen cargada en el frame ${currentFrame}.`);
        });
      };
      img.src = e.target.result;
    };
    reader.readAsDataURL(file);
  }


  handleAnimation = () => {
    this.setState({ isAnimating: !this.state.isAnimating }, this.animate);
  };

  // Animación más lenta (1/3 de la velocidad)
  animate = () => {
    if (!this.state.isAnimating) return;
    const { sprites, currentFrame } = this.state;
    const nextFrame = (currentFrame + 1) % 5; // Ciclo entre los 5 frames
    const ctx = this.ctx;
    ctx.clearRect(0, 0, 64, 64); // Limpia el canvas
    if (sprites[nextFrame]) {
      ctx.putImageData(sprites[nextFrame], 0, 0); // Dibuja el siguiente frame
    }
    this.setState({ currentFrame: nextFrame });
    setTimeout(this.animate, 300); // Reducir la velocidad (300ms por frame)
  };

  toggleEraser = () => {
    this.setState({ isErasing: !this.state.isErasing });
  };

  handleColorChange = (color) => {
    this.setState({ drawingColor: color.hex });
  };

  increaseStrokeSize = () => {
    this.setState((prevState) => ({ strokeSize: prevState.strokeSize + 1 }));
  };

  decreaseStrokeSize = () => {
    this.setState((prevState) => ({
      strokeSize: Math.max(prevState.strokeSize - 1, 1), // Evita que el tamaño sea menor a 1
    }));
  };

  render() {
    const { isAnimating, sprites, currentFrame, isErasing, drawingColor } = this.state;
    return (
      <div>
        <h3>Editor FX</h3>
        <Row>
          <Col>
            <canvas
              ref={this.canvasRef}
              width={64}
              height={64}
              style={{ border: '1px solid black' }}
            />
          </Col>
          <Col>
            <div>
              <Card>
                <CardBody>
                  <button onClick={this.toggleEraser}>
                    {isErasing ? (
                      <>
                        <FaEraser /> Borrador Activado
                      </>
                    ) : (
                      <>
                        <FaPaintBrush /> Borrador Desactivado
                      </>
                    )}
                  </button>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <button onClick={this.decreaseStrokeSize}>-</button>
                  <span>Tamaño de trazo: {this.state.strokeSize}</span>
                  <button onClick={this.increaseStrokeSize}>+</button>
                </CardBody>
              </Card>
            </div>
            <div>
              <SketchPicker
                color={drawingColor}
                onChange={this.handleColorChange}
                disableAlpha={true} // Solo colores sólidos
              />
            </div>
          </Col>
        </Row>

        <input
          type="file"
          ref={this.imageInputRef}
          accept="image/*"
          onChange={this.handleLoadImage}
        />
        <br />
        <br />
        <button onClick={this.handleAnimation}>
          {isAnimating ? 'Detener Animación' : 'Iniciar Animación'}
        </button>
        <div style={{ display: 'flex', marginLeft: '20px' }}>
          {sprites.map((sprite, index) => (
            <div
              key={index}
              onClick={() => this.handleFrameSelection(index)}
              style={{
                width: 64,
                height: 64,
                border: index === currentFrame ? '2px solid blue' : '1px solid black',
                margin: '0 5px',
                cursor: 'pointer',
              }}
            >
              <canvas
                width={64}
                height={64}
                ref={(el) => {
                  if (el && sprite) {
                    const ctx = el.getContext('2d');
                    ctx.putImageData(sprite, 0, 0);
                  }
                }}
              />
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default FXmaker;
