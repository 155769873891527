import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { collection, doc, getDoc } from 'firebase/firestore';
import db from '../firebase';
import { LoadProduction } from '../store/MetaStore';
import { Button, Card, CardBody, CardText } from 'react-bootstrap';
import CreateAppUser from './CreateAppUser';
import { FaSquareFacebook } from 'react-icons/fa6';

export default class LoadAppUser extends Component {
  constructor(props) {
    super(props)
    this.state = LoadProduction.init
    this.state.modalAlert = false
    this.handleConsole = this.handleConsole.bind(this)
  }

  async componentDidMount() {
    const { pathname } = window.location;

    const cleanedPathname = pathname.startsWith('/') ? pathname.substring(1) : pathname;
    
    const license = await doc(db, '$:__sites-name', cleanedPathname)
    if (cleanedPathname !== '') {
      const isLicenseAvalible = await getDoc(license)
      this.state = isLicenseAvalible.data()
      isLicenseAvalible.exists() ?
        this.setState({
          ...isLicenseAvalible.data(),
          pageExist: true
        })
        :
        setTimeout(() => {
          alert('este sitio no existe'); window.location = '/'
        }, 1500);
    }
  }

  handleConsole() {
    this.setState({
      utaStep: true,
      frameOn: ''
    })
    console.log(this.state)
  }


  render() {
    const { btnTxtTitle } = this.state;

    return (
      <div style={this.state.choiseColorBackground}>
        <header className='App-header'>
          <div className={this.state.choiseStyleMain + ' ' + this.state.frameOn}>
            {this.state.utaStep === false ?
              <Button onClick={this.handleConsole} className={`${this.state.choiseBtnColor}`}>
                {btnTxtTitle ? btnTxtTitle[6] : 'Loading...'}
              </Button > : this.state.modalWelcome === true ? <CreateAppUser config={this.state} /> : <Card>
                <CardBody>
                  <CardText>{this.state.txtModalWelcome}</CardText>
                </CardBody>
              </Card>}
          </div>
          {this.state.isValidUrl ? (
            <div style={{ bottom: '0', color: 'blue', fontSize: '24px', position: 'absolute' }}>
              <FaSquareFacebook className='c-pointer' onClick={()=>{window.location=this.state.pointFaceUrl}}/>
            </div>
          ) : (
           <></>
          )}
        </header>

      </div>
    )
  }
}
